import React, { FC } from 'react'
import Link from 'next/link'
import { gameCategories } from '@/contexts/Category'
import styles from './index.module.scss'

const Category: FC<{}> = ({}) => {
  return (
    <div className={styles.category}>
      {gameCategories.map((item, index) => (
        <Link
          href={`/c/${item.slug}`}
          key={index + item.label}
          //   onClick={() => clickGame(item.typeId, item.id, item.title)}
        >
          {item.icon} <span>{item.label}</span>
        </Link>
      ))}
    </div>
  )
}

export default Category
