export interface Games {
  id: string
  title: string
  slug: string
  description: string
  thumb: string
  url: string
  typeId: string
  gameId: string
  // 其他游戏字段...
}

export function getGameHistory(): Games[] {
  // 确保在客户端环境执行
  if (typeof window === 'undefined') return []

  try {
    const history = localStorage.getItem('gameHistory')
    if (!history) return []

    const parsed = JSON.parse(history)
    // 添加数据验证
    return Array.isArray(parsed) ? parsed : []
  } catch (error) {
    console.error('读取游戏历史记录失败:', error)
    return []
  }
}

// 添加游戏到历史记录
export function addGameToHistory(game: Games): void {
  if (typeof window === 'undefined') return

  try {
    const history = getGameHistory()

    // 去重 - 如果游戏已存在，先移除旧的
    const filteredHistory = history.filter((item) => item.id !== game.id)

    // 添加到数组开头（最新玩的排前面）
    const newHistory = [game, ...filteredHistory]

    // 限制历史记录数量（例如只保留最近20个）
    const limitedHistory = newHistory.slice(0, 20)

    localStorage.setItem('gameHistory', JSON.stringify(limitedHistory))
  } catch (error) {
    console.error('保存游戏历史记录失败:', error)
  }
}

// 清空游戏历史记录
export function clearGameHistory(): void {
  if (typeof window === 'undefined') return
  localStorage.removeItem('gameHistory')
}

// 根据游戏ID删除单个历史记录
export function removeGameFromHistory(gameId: string): void {
  if (typeof window === 'undefined') return

  try {
    const history = getGameHistory()
    const updatedHistory = history.filter((game) => game.id !== gameId)
    localStorage.setItem('gameHistory', JSON.stringify(updatedHistory))
  } catch (error) {
    console.error('删除游戏历史记录失败:', error)
  }
}
