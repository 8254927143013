import { FC } from 'react'
import styles from './index.module.scss'

const Desc: FC = () => {
  const descList = [
    {
      title: 'What is H5FreeGames?',
      desc: 'H5FreeGames is your ultimate destination for unlimited free online games! Dive into our vast collection of exciting HTML5 games that cater to all tastes and preferences. Whether you love thrilling aerial combat in 1945 Air Force: Airplane, strategic traffic puzzles in Jam Puzzle Collection, addictive matching in PopStar Mania, colorful racing in Pencil Rush Online, or classic arcade fun in Crossy Chicken, we have something special just for you. With just a few clicks, endless entertainment is at your fingertips!',
    },
    {
      title: 'No Downloads Needed',
      desc: "Tired of wasting time on downloads and filling up your phone's storage? At H5FreeGames, all our games run instantly in your web browser—no downloads or installations required! Enjoy seamless gaming anytime, anywhere, without the hassle.",
    },
    {
      title: '100% Free to Play',
      desc: "We believe everyone deserves access to great games without any cost. That's why every game on H5FreeGames is completely free to play—no hidden fees, no subscriptions, just pure fun for everyone!",
    },
    {
      title: 'Fresh Games Added Regularly',
      desc: "We're always on the lookout for the latest and greatest games to keep your experience exciting and up-to-date. We constantly add new titles and challenges that will keep you entertained for hours.",
    },
    {
      title: 'Fun for All Ages',
      desc: "Gaming is a universal joy, and our collection reflects that. Whether you're looking for family-friendly fun, solo adventures, or friendly competitions, H5FreeGames offers something for everyone. Kids, teens, and adults alike can find their perfect game here!",
    },
    {
      title:
        'Visit H5FreeGames today and start playing your favorite games for free!',
      desc: '(All games are browser-based and compatible with mobile and desktop devices—no registration required!)',
    },
    {
      title: 'Why Choose H5FreeGames?',
      desc: '✔ Huge Variety: Hundreds of games across multiple genres\n✔ Instant Play: No waiting, no downloads—just click and play\n✔ Always Free: No payments, no restrictions\n✔ Regular Updates: New games added frequently\n✔ Family-Friendly: Safe and enjoyable for players of all ages',
    },
    {
      title: 'Featured Games:',
      desc: '⭐️ 1945 Air Force: Airplane - Classic aerial combat\n⭐️ PopStar Mania - Addictive block-matching\n⭐️ Crossy Chicken - Fun arcade adventure\n⭐️ Pencil Rush Online - Colorful 3D racing\n⭐️ Jam Puzzle Collection - Strategic traffic puzzles',
    },
  ]
  return (
    <div className={styles.desc}>
      <h1>{'H5FreeGames: Unlimited Free Online Games Portal'}</h1>
      <div className={styles.descList}>
        {descList.map((item) => (
          <div className={styles.descItem} key={item.title}>
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
          </div>
        ))}
      </div>
      <p>
        {
          'Join millions of players worldwide and experience the best free online games at H5FreeGames!'
        }
      </p>
    </div>
  )
}

export default Desc
