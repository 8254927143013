import React, { FC, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Link from 'next/link'
import Image from 'next/image'
import { PlayIcon } from '@/components/Icons'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { Pagination, Autoplay } from 'swiper/modules'
import styles from './index.module.scss'
import 'swiper/css'
import 'swiper/css/effect-cards'

type ImgSwiperProps = {
  data: any[]
}

const ImgSwiper: FC<ImgSwiperProps> = ({ data }) => {
  const [list, setList] = useState(data)
  useEffect(() => {
    setList(data)
  }, [data])

  const clickGame = (type: string, id: string, title: string) => {
    window.gtag('event', 'click_game_card', {
      game_type: type,
      game_id: id,
      game_name: title,
    })
  }

  return (
    <div className={styles.imgSwiper}>
      <Swiper
        slidesPerView={'auto'}
        initialSlide={0}
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // loop={true}
        navigation={true}
        modules={[Pagination]}
        className="mySwiper"
      >
        {list.map((item: any, index: number) => (
          <SwiperSlide key={index}>
            <Link
              key={item.id + index}
              href={`/game/${item.slug}`}
              className={styles.item}
              onClick={() => clickGame(item.typeId, item.id, item.title)}
            >
              <Image
                src={item.thumb}
                alt={item.title}
                width={512}
                height={384}
                priority={true}
              />
              <div className={styles.info}>
                <span>{item.title}</span>
                <div className={styles.play}>
                  <PlayIcon />
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
export default ImgSwiper
