import { Game } from '@/lib/games'
import Link from 'next/link'
import Image from 'next/image'
import { getGamesBySearchAndCategory } from '@/utils/helper'
import styles from './index.module.scss'

interface GameListProps {
  data: Game[]
  categories: { label: string; slug: string }[] // 分类数组
  imageSize?: { width: number; height: number } // 图片尺寸配置
}

export function GameList({
  data,
  categories,
  imageSize = { width: 145, height: 82 },
}: GameListProps) {
  const gameList = data

  return (
    <>
      {categories.map((category) => {
        const categoryGames = getGamesBySearchAndCategory(
          gameList,
          '',
          category.label
        )
        return (
          <div key={category.label} className={styles.category}>
            <Link href={`/c/${category.slug}`}>
              <h2>{category.label}</h2>
              <span>{'>>'}</span>
            </Link>

            <div className={styles.gameList}>
              {categoryGames.map((game) => (
                <Link key={game.id} href={`/game/${game.slug}`}>
                  <Image
                    loading="lazy"
                    src={game.thumb}
                    alt={game.title}
                    width={120}
                    height={90}
                    className={styles.gameImage}
                  />
                </Link>
              ))}
            </div>
          </div>
        )
      })}
    </>
  )
}
