import React, { FC } from 'react'
import Head from 'next/head'

const Meta: FC<{ title: string; desc: string }> = ({ title, desc }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
    </Head>
  )
}

export default Meta
