import React, { useEffect, useState } from 'react'
import type { GetServerSideProps } from 'next'
import { getAllGames, Game } from '@/lib/games'
import { GetStaticProps } from 'next'
import { getGameHistory, Games } from '@/utils/gameStorage'
import { gameCategories } from '@/contexts/Category'
import Link from 'next/link'
import Meta from '@/components/Meta'
import Image from 'next/image'
import styles from '@/styles/index.module.scss'
import ImgSwiper from '@/components/Swiper'
import Desc from '@/components/Desc'
import { GameList } from '@/components/GameList'
import Category from '@/components/Category'

export default function Home(props: { gamesList: Game[] }) {
  const { gamesList } = props
  const [playHistory, setPlayHistory] = useState<Games[]>([])

  const clickGame = (type: string, id: string, title: string) => {
    window.gtag('event', 'click_game_card', {
      game_type: type,
      game_id: id,
      game_name: title,
    })
  }

  useEffect(() => {
    // 仅在客户端执行获取历史记录
    setPlayHistory(getGameHistory())
  }, [])

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        const navigationEntry = performance.getEntriesByType('navigation')[0]
        window.gtag('event', 'home_load_start', {})
        if (navigationEntry) {
          // DOM 加载完成时间(不包括异步加载的资源,如图片，脚本等)
          const domLoadTime =
            // @ts-ignore
            navigationEntry.domContentLoadedEventEnd - navigationEntry.startTime

          // 页面加载完成总时间(所有资源的加载时间,ms)

          const loadTime =
            // @ts-ignore
            navigationEntry.loadEventEnd - navigationEntry.startTime

          window.gtag('event', 'home_domload_end', {
            duration: domLoadTime.toFixed(2),
          })
          window.gtag('event', 'home_load_end', {
            duration: loadTime.toFixed(2),
          })
        }
      }, 100)
    }
    // 直接调用一次以处理页面可能已经加载的情况
    if (document.readyState === 'complete') {
      handleLoad()
    } else {
      window.addEventListener('load', handleLoad)
    }

    return () => {
      window.removeEventListener('load', handleLoad)
    }
  }, [])

  return (
    <>
      <Meta
        title={'Top H5 Free Games - Your Ultimate Games Hub Online'}
        desc={
          'H5freegames.com is a free online games platform, offering top and popular H5 games. You can enjoy high-quality free games without downloads!'
        }
      />
      {/* 如果有历史记录 */}
      {playHistory.length > 0 && (
        <div className={styles.history}>
          <Link href="/played">{'Recently Played'}</Link>
          <div className={styles.playList}>
            {playHistory.map((item: any, index: number) => (
              <Link
                key={item.id + index}
                href={`/game/${item.slug}`}
                className={styles.item}
                onClick={() => clickGame(item.typeId, item.id, item.title)}
              >
                <Image
                  src={item.thumb}
                  alt={item.title}
                  loading="lazy"
                  width={50}
                  height={50}
                />
              </Link>
            ))}
          </div>
        </div>
      )}
      <ImgSwiper
        data={gamesList.filter((item: Game) => item.typeId === '001')}
      />
      <div className={styles.gameList}>
        {/* <div className={styles.blockGradient}></div> */}
        <div className={styles.recommend}>
          <Link href="/recommended">
            <h2>✨ Recommended</h2> <span>{'>>'}</span>
          </Link>
          <div className={styles.recommendList}>
            {gamesList
              .filter((item: Game) => item.typeId === '002')
              .map((item: any, index: number) => (
                <Link
                  key={item.id + index}
                  href={`/game/${item.slug}`}
                  className={styles.item}
                  onClick={() => clickGame(item.typeId, item.id, item.title)}
                >
                  <Image
                    src={item.thumb}
                    alt={item.title}
                    width={512}
                    height={384}
                    priority={true}
                  />
                  <p>{item.title}</p>
                </Link>
              ))}
          </div>
        </div>

        <div className={styles.hot}>
          <Link href="/hot-games">
            <h2>🔥 Hot Games</h2> <span>{'>>'}</span>
          </Link>
          <div className={styles.hotList}>
            {gamesList
              .filter((item: Game) => item.typeId === '003')
              .slice(0, 9)
              .map((item: any, index: number) => (
                <Link
                  key={item.id + index}
                  href={`/game/${item.slug}`}
                  className={styles.item}
                  onClick={() => clickGame(item.typeId, item.id, item.title)}
                >
                  <Image
                    src={item.thumb}
                    alt={item.title}
                    loading="lazy"
                    width={118}
                    height={88}
                  />
                  <p>{item.title}</p>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <Desc />
      <Category />
      <GameList categories={gameCategories} data={gamesList} />
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const allGames = await getAllGames()

  const gamesList = allGames.map((game) => ({
    id: game.id,
    title: game.title,
    slug: game.slug,
    thumb: game.thumb,
    typeId: game.typeId,
    category: game.category,
    tags: game.tags,
    // 只保留渲染必需的字段
  }))
  return {
    props: { gamesList },
    // 重新验证时间(秒)
    revalidate: 60,
  }
}
